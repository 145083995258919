.list {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}

.rated{
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.container {
  display: inline-block;
  margin: 10px 10px 15px 10px;
}

.title {
  text-align: center;
  font-size: large;
  font-weight: 500;
  padding: 0px;
  height: 4rem;
  margin-bottom: 20px;
}

.title span {
  opacity: 1;
  display: block;
  padding: 0.5rem;
  border-radius: 8px;
  line-height: normal;
  background: #eefbff 0% 0% no-repeat padding-box;
}

.image img {
  height: 15rem;
}

.description {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1rem;
}
