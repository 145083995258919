.container {
  display: flex;
  flex-direction: column;
  gap: 15px; 
  width: 100%;
  max-width: 500px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-left: -8px;
  margin-right: -8px;;
}

.middleColumn,
.completeColumn {
  padding-left: 8px;
  padding-right: 8px;
}

.middleColumn {
  max-width: 50%;
  flex: 0 0 50%;

}

.completeColumn {
  max-width: 1000%;
  flex: 0 0 100%;
}

@media( min-width: 768px ){
  .container {
    width: 70%;
    gap: 2rem; 
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;;
  }

  .middleColumn,
  .completeColumn {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media( min-width: 451px ) and ( max-width: 768px ) {
  .container {
    gap: 30px;
  }
}
